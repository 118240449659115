export const formatMoney = (value, type = 'R$') => {
  let formatted = '';
  let num = '';
  const isNegative = value < 0;

  console.log('Valor = ' + value);

  if (value || value === 0) {
    switch (type) {
      case 'US$':
        // Formatação para US$ pode ser adicionada aqui
        break;
      case 'R$':
      default:
        // Usa valor absoluto para manipular o número sem o sinal
        num = Math.abs(value).toFixed(2).split('.');
        num[0] = `${num[0].split(/(?=(?:...)*$)/).join('.')}`;
        formatted = num.join(',');

        // Adiciona o tipo de moeda e ajusta o sinal negativo se necessário
        formatted = `${type} ${isNegative ? '-' : ''}${formatted}`;
        break;
    }
  }

  return formatted;
};

const formatingNumber = (value, fixed, per = false) => {
  let formatted = '';
  let num = '';
  num = value.toFixed(fixed).split('.');
  num[0] = `${num[0].split(/(?=(?:...)*$)/).join('.')}`;
  formatted = num.join(',');
  if (per) formatted += '%';

  return formatted;
};

export const dataFormatting = (data) => {
  switch (data.id) {
    case 'PRODUCAO_LEITE_MENSAL':
    case 'PRODUCAO_LEITE_DIARIA':
    case 'VACAS_EM_LACTACAO':
    case 'PRODUTIVIDADE_MAO_DE_OBRA':
    case 'CCS':
    case 'CBT':
      return formatingNumber(data.value, 0);
    case 'DIAS_HOMENS_TRABALHADO':
      return formatingNumber(data.value, 1);
    case 'GORDURA':
    case 'PROTEINA':
    case 'RETENCAO_PLACENTA':
    case 'CASOS_MASTITE':
    case 'PROBLEMAS_CASCO':
      return formatingNumber(data.value, 1, true);
    case 'PRECO_LEITE':
    case 'PRODUTIVIDADE_VACAS_EM_LACTACAO':
    case 'GASTO_MEDICAMENTO_LEITE_PRODUZIDO':
    case 'GASTO_CONCENTRADO_LEITE_PRODUZIDO':
    case 'GASTO_VOLUMOSO_LEITE_PRODUZIDO':
    case 'GASTO_MDO_LEITE_PRODUZIDO':
    case 'GASTO_NUCLEOS_LEITE_PRODUZIDO':
    case 'GASTO_DIETA_LEITE_PRODUZIDO':
      return formatingNumber(data.value, 2);
    default:
      return data.value;
  }
};
