import apiNew from '../apiNew';

const fetchConfinements = (params = {}) => {
  return apiNew.get(`/confinements`, {
    params: {
      ...params,
    },
  });
};

const fetchConfinementsbyId = (id, params = {}) => {
  return apiNew.get(`/confinements/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerConfinements = (body = {}) => {
  return apiNew.post(`/confinements`, body);
};

const changeConfinementsbyId = (id, body = {}) => {
  return apiNew.put(`/confinements/${id}`, body);
};

const changeConfinementsValidate = (type, body = {}) => {
  return apiNew.put(`/confinements/validate?type=${type}`, body);
};

const deleteConfinements = (id) => {
  return apiNew.delete(`/confinements/${id}`);
};

const fetchConfinementsKPI = (params = {}) => {
  return apiNew.get(`/confinements/graphic_kpi`, {
    params: {
      ...params,
    },
  });
};
const fetchConfinementsLine = (params = {}) => {
  return apiNew.get(`/confinements/graphic_line`, {
    params: {
      ...params,
    },
  });
};

export {
  fetchConfinements,
  fetchConfinementsbyId,
  registerConfinements,
  changeConfinementsbyId,
  deleteConfinements,
  fetchConfinementsKPI,
  fetchConfinementsLine,
  changeConfinementsValidate,
};
