import apiNew from '../apiNew';

const fetchTeams = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/teams`, {
    params: {environment, ...params},
  });
};
const registerTeam = (body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.post(`/teams`, {environment, ...body});
};

const changeTeambyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.put(`/teams/${id}`, {environment, ...body});
};

const changeTeamValidate = (type, body = {}) => {
  return apiNew.put(`/teams/validate?type=${type}`, body);
};

const deleteTeam = (id) => {
  return apiNew.delete(`/teams/${id}`);
};
const fetchCardTeamValidated = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/teams/isValidated`, {
    params: {environment, ...params},
  });
};
const fetchCardTeamFunctionXSalary = (params = {}) => {
  return apiNew.get(`/teams/function_average_salary`, {
    params: {
      ...params,
    },
  });
};
const fetchCardTeamFunctionXPercentSalary = (params = {}) => {
  return apiNew.get(`/teams/function_percentage_salary`, {
    params: {
      ...params,
    },
  });
};
export {fetchTeams, registerTeam, changeTeambyId, deleteTeam, fetchCardTeamFunctionXSalary, fetchCardTeamFunctionXPercentSalary, fetchCardTeamValidated, changeTeamValidate};
