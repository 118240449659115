import api from '../api';
import apiNew from '../apiNew';

const fetchCustomers = (params = {}) => {
  return api.get(`/customers/find_for_combo`, {
    params: {
      ...params,
    },
  });
};
const fetchCustomersTotal = (params = {}) => {
  return api.get(`/customers`, {
    params: {
      ...params,
    },
  });
};

const fetchCustomersForCombo = (technicalId = null, params = {}) => {
  return api.get(`/customers/find_for_combo${technicalId ? `?technical_id=${technicalId}` : ''}`, {
    params: {
      ...params,
    },
  });
};

const fetchCustomersbyId = (id, params = {}) => {
  return api.get(`/customers/${id}`, {
    params: {
      ...params,
    },
  });
};

const updtateFetchCustomers = (id, body = {}) => {
  return api.put(`/customers/${id}`, body);
};
const intialRegistration = (body = {}) => {
  return api.post(`/initial_registration`, body);
};

const registerCustomers = (body = {}) => {
  return api.post(`/customers`, body);
};
const changePassword = (id = null, body) => {
  return api.post(`/users/${id}/changepassword`, body);
};

const deleteCustomers = (id) => {
  return api.delete(`/customers/${id}`);
};

const changeCustomersbyId = (id, body = {}) => {
  return api.put(`/customers/${id}`, body);
};

export {
  fetchCustomers,
  fetchCustomersForCombo,
  fetchCustomersbyId,
  registerCustomers,
  updtateFetchCustomers,
  deleteCustomers,
  changeCustomersbyId,
  changePassword,
  fetchCustomersTotal,
  intialRegistration,
};
