/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import IMask from 'imask';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import columns from './WeaningBirthDataGridModel';
import {fetchBirthWeaning, 
  updateLiveReproductionBirth, 
  updateReproductionWeaning, 
  changeLiveReproductionBirthValidate,
  changeReproductionWeaningValidate,} from '../../services/livestock';

const WeaningBirthGrid = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [totalItems] = useState(0);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParamsCustom, stateMessage} = useContext(DataContext);
  const metaData = {
    created_by: {
      id: state.user.id,
    },
    harvest: {
      id: state.harvest.id,
    },
    customer: {
      id: state.customer.id,
    },
    farm: {
      id: state.farm.id,
    },
  };

  useEffect(() => {
    if (state.farm && state.farm.id) {
      metaData.farm = {id: state.farm.id};
    }
  }, [state.farm]);// eslint-disable-line

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    /*dataGridRows
      .filter((item) => item.amount || item.weight)
      .forEach((row) => {
        row = Object.assign(row, metaData);
        delete row.id;
        promises.push(
          row.type === 'BIRTH'
            ? updateLiveReproductionBirth(Object.assign(row, {validated: checked}))
            : updateReproductionWeaning(Object.assign(row, {validated: checked})),
        );
      });*/

      setIsLoading(true);
      const birthIds = dataGridRows
            .filter(item => item.type === "BIRTH" && item.codigo !== null && item.codigo !== undefined)
            .map(item => item.codigo);    

      const weaningIds = dataGridRows
            .filter(item => item.type === "WEANING" && item.codigo !== null && item.codigo !== undefined) 
            .map(item => item.codigo);   

      await changeLiveReproductionBirthValidate(checked, birthIds);

      await changeReproductionWeaningValidate(checked, weaningIds);

    const response = await Promise.all(promises);
    setUpdate(!update);
    setIsLoading(false);
    return response;
  };
  useEffect(() => {
    const loadReproduction = async () => {
      setIsLoading(true);
      const response = await fetchBirthWeaning(stateFetchParamsCustom);

      response.data = response.data.filter((item, i) => {
        if (item.category_reference.title.indexOf(':safraname') > -1) {
          item.category_reference.title = item.category_reference.title.replace(':safraname', `(${state.harvest.first_year}/${state.harvest.last_year})`);
        } else if (item.category_reference.title.indexOf(':ano2') > -1) {
          item.category_reference.title = item.category_reference.title.replace(':ano2', `(${state.harvest.last_year})`);
        }
        item.codigo =item.id;
        item.id = i + 1;
        return item;
      });

      setIsLoading(false);
      
      setData(
        response.data.map((item) => {
          if (!item.amount && !item.weight) {
            return {...item, validated: false};
          }
          return item;
        }),
      );
    };
    loadReproduction();
  }, [update, stateFetchParamsCustom]);// eslint-disable-line

  const actions = [
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (dataValues) => {
        if (!dataValues.validated) {
          Object.assign(dataValues, {validated: false});
        }

        dataValues.category_reference = {
          id: dataValues.category_reference.id,
        };
        dataValues.category_weaning = {id: dataValues.category_weaning.id};

        dataValues.weight = dataValues.weight !== null ? -1 : null;
        dataValues.amount = dataValues.amount !== null ? -1 : null;
        try {
          if (dataValues.type === 'BIRTH') {
            delete dataValues.weight;
            delete dataValues.id;
            const resp = await updateLiveReproductionBirth({
              ...dataValues,
              ...metaData,
            });
            setUpdate(!update);
            return resp;
          }
          const resp = await updateReproductionWeaning({
            ...dataValues,
            ...metaData,
          });
          setUpdate(!update);
          return resp;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  return (
    <>
      {stateMessage}
      {data && (
        <div className="weaningBirth">
          <DataGrid
            // eslint-disable-next-line no-shadow
            onChange={async (dataValues) => {
              if (!dataValues.validated) {
                Object.assign(dataValues, {validated: false});
              }

              dataValues.category_reference = {
                id: dataValues.category_reference.id,
              };
              dataValues.category_weaning = {
                id: dataValues.category_weaning.id,
              };

              dataValues.weight = dataValues.weight !== null ? parseInt(dataValues.weight) : null;
              dataValues.amount = dataValues.amount !== null ? parseInt(dataValues.amount) : null;
              try {
                if (dataValues.amount !== null || dataValues.weight !== null) {
                  if (dataValues.type === 'BIRTH') {
                    delete dataValues.weight;
                    delete dataValues.id;
                    const resp = await updateLiveReproductionBirth({
                      ...dataValues,
                      ...metaData,
                    });
                    setUpdate(!update);
                    return resp;
                  }
                  const resp = await updateReproductionWeaning({
                    ...dataValues,
                    ...metaData,
                  });
                  setUpdate(!update);
                  return resp;
                }
                return null;
              } catch (error) {
                console.error(error);
                return error;
              }
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            maxHeight="100%"
            editable=""
            actions={actions}
            accumulators={{
              Total: {
                columns: ['amount', 'weight'],
                itemOperation: (acc, line, key) => {
                  if (key === 'weight') {
                    return {
                      sum: acc ? (acc.sum += line[key] * line.amount) : line[key] * line.amount,
                      quantity: acc ? (acc.quantity += line.amount) : line.amount,
                    };
                  }
                  return (acc += line[key] || 0);
                },
                resultOperation: (acc, key) => {
                  return key === 'weight'
                    ? `Ponderado: ${IMask.createMask({
                        mask: 'number',
                        blocks: {
                          number: {
                            mask: Number, // enable number mask

                            // other options are optional with defaults below
                            scale: 2, // digits after point, 0 for integers
                            signed: false, // disallow negative
                            thousandsSeparator: '.', // any single char
                            padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                            normalizeZeros: true, // appends or removes zeros at ends
                            radix: ',', // fractional delimiter

                            // additional number interval options (e.g.)
                            min: 0,
                            max: 999999999999,
                          },
                        },
                      }).resolve((acc.sum / acc.quantity).toString())}`
                    : acc;
                },
              },
              Ponderado: {
                generalOnly: true,
                columns: ['amount', 'weight'],

                itemOperation: (acc, line, key) => {
                  if (acc) {
                    if (key === 'weight') {
                      return {
                        sum: (acc.sum += line[key] * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                  }
                  return {
                    sum: acc ? (acc.sum += line[key] * line.amount) : line[key] * line.amount,
                    quantity: acc ? (acc.quantity += line.amount) : line.amount,
                  };
                },
                resultOperation: (acc) => {
                  const value = IMask.createMask({
                    mask: 'number',
                    blocks: {
                      number: {
                        mask: Number, // enable number mask

                        // other options are optional with defaults below
                        scale: 2, // digits after point, 0 for integers
                        signed: false, // disallow negative
                        thousandsSeparator: '.', // any single char
                        padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                        normalizeZeros: true, // appends or removes zeros at ends
                        radix: ',', // fractional delimiter

                        // additional number interval options (e.g.)
                        min: 0,
                        max: 999999999999,
                      },
                    },
                  }).resolve((acc.sum / acc.quantity).toString());
                  return value;
                },
              },
            }}
            disabledPaginate
            totalItems={totalItems}
            groupBy="category_reference"
            validateCallback={validateHeaderCallback}
          />
        </div>
      )},
      {isLoading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}
    </>
  );
};

export default WeaningBirthGrid;
