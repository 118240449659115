import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import moment from 'moment';
import DataGrid from '../../components/DataGrid';
import {ROLES, MASKS} from '../../utils/constants';
import {createOrUpdateDataFull, isObject, maskInput} from '../../utils/utils';
import {DataContext} from '../../utils/DataProvider';
import {fetchBirths, changeBirthsbyId, registerBirths, deleteBirths, changeBirthsValidate} from '../../services/births';

const BirthGrid = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getContents = async () => {
      try {
        const response = await fetchBirths({
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItems(response.data.size);
        setData(response.data.resultList);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [reset, stateFetchParams]);// eslint-disable-line
  const validationFindData = (dataTemp) => {
    let validated = true;
    dataTemp.forEach((row) => {
      validated = validated && row.validated;
    });
    return dataTemp.length ? validated : false;
  };

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    setIsLoading(true);
    /*dataGridRows.forEach((row) => {
      if (row.notCreated) {
        return;
      }
      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, registerBirths, changeBirthsbyId));
    });*/

    const list = dataGridRows.map(item => item.id);

    await changeBirthsValidate(checked, list); 

    const response = await Promise.all(promises);
    setReset(!reset);
    setIsLoading(false);
    return response;
  };
  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '30%',
      editType: 'datepicker',
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => {
        // eslint-disable-next-line eqeqeq
        return value === 'true' || value;
      },
      popoverMapper: (line) => {
        return `${line.period}`;
      },
      mapper: (line, editMode) => {
        const [date] = line.date.split(' ');
        const [day, month, year] = date.split('/');

        if (!editMode) {
          // eslint-disable-next-line eqeqeq
          if (line.period === true) {
            return moment(new Date(year, month - 1, day))
              .locale('pt-br')
              .format('MM/YYYY');
          }

          return moment(new Date(year, month - 1, day))
            .locale('pt-br')
            .format('DD/MM/YYYY');
        }

        return moment(new Date(year, month - 1, day));
      },
      returnFormat: (value) => {
        if (moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00') === 'Invalid date') {
          return value;
        }
        return moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
      },
    },
    {
      key: 'amount',
      field: 'amount',
      required: true,
      name: 'Quantidade',
      width: '30%',
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'sex',
      valueKey: 'sex',
      field: 'sex',
      width: state.user.user_type !== ROLES.CUSTOMER ? '30%' : '40%',
      required: true,
      name: 'Sexo',
      textAlign: 'Center',
      editType: 'dropdownedit',
      mapper: (line, editMode) => {
        const response = [
          {
            value: 'MALE',
            name: 'Macho',
          },
          {value: 'FEMALE', name: 'Fêmea'},
        ];
        const option = response.find((responseValue) => responseValue.value === line.sex);
        if (!editMode) {
          return option.name;
        }

        return {value: option.value, label: option.name};
      },
      returnFormat: (value) => {
        if (isObject(value)) {
          return value.value;
        }
        return value;
      },
      optionsFetch: async () => {
        try {
          const response = [
            {
              value: 'MALE',
              name: 'Macho',
            },
            {value: 'FEMALE', name: 'Fêmea'},
          ];
          const objectTemp = [];
          response.map((item) =>
            objectTemp.push({
              value: item.value,
              label: item.name,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      width: '10%',
      textAlign: 'Center',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteBirths(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  return (
    <>
      {stateMessage}
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            Reflect.deleteProperty(dataGridInputs, 'sexString');
            if (!dataGridInputs.date) {
              const date = moment(new Date()).format('DD/MM/YYYY 00:00:00');
              Object.assign(dataGridInputs, {date});
            }
            if (dataGridInputs && dataGridInputs.sex && dataGridInputs.sex.id) {
              dataGridInputs.sex = dataGridInputs.sex.title;
            }
            if (dataGridInputs && dataGridInputs.validated === undefined) {
              Object.assign(dataGridInputs, {validated: false});
            }
            Object.assign(dataGridInputs, {status: false});
            delete dataGridInputs.sexString;
            const response = await createOrUpdateDataFull(dataGridInputs, state, registerBirths, changeBirthsbyId);
            setReset(!reset);
            return response;
          }}
          header={columns}
          data={data}
          width="inherit"
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          validateCallback={validateHeaderCallback}
          editable=""
          actions={actions}
          totalItems={totalItems}
          accumulators={{
            'Total geral': {
              columns: ['amount'],
              itemOperation: (acc, line, key) => (acc += line[key]),
              resultOperation: (acc) => {
                return maskInput({value: acc, type: MASKS.DOUBLE});
              },
            },
          }}
        />
      )},
      {isLoading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}
    </>
  );
};

export default BirthGrid;
