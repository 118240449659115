import apiNew from '../apiNew';

const fetchClimates = (params = {}) => {
  return apiNew.get(`/climates`, {
    params: {
      ...params,
    },
  });
};

const fetchClimatesbyId = (id, params = {}) => {
  return apiNew.get(`/climates/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeClimatebyId = (id, body = {}) => {
  return apiNew.put(`/climates/${id}`, body);
};

const registerClimates = (body = {}) => {
  return apiNew.post(`/climates`, body);
};

const deleteClimate = (id) => {
  return apiNew.delete(`/climates/${id}`);
};

export {
  fetchClimates,
  fetchClimatesbyId,
  changeClimatebyId,
  registerClimates,
  deleteClimate,
};
