import apiNew from '../apiNew';

const fetchStocks = (params = {}) => {
  return apiNew.get(`/stocks`, {
    params: {
      ...params,
    },
  });
};

const fetchStockbyId = (id, params = {}) => {
  return apiNew.get(`/stocks/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeStocksbyId = (id, body = {}) => {
  return apiNew.put(`/stocks/${id}`, body);
};

const changeStocksValidate = (type, body = {}) => {
  return apiNew.put(`/stocks/validate?type=${type}`, body);
};

const registerStock = (body = {}) => {
  return apiNew.post(`/stocks`, body);
};

const deleteStock = (id) => {
  return apiNew.delete(`/stocks/${id}`);
};

export {
  fetchStocks,
  fetchStockbyId,
  changeStocksbyId,
  registerStock,
  deleteStock,
  changeStocksValidate,
};
