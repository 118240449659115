import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import * as Yup from 'yup';
import moment from 'moment';
import DataGrid from '../../components/DataGrid';
import {maskInput, createOrUpdateDataFull} from '../../utils/utils';
import {MASKS} from '../../utils/constants';
import {DataContext} from '../../utils/DataProvider';
import {fetchDeaths, registerDeaths, changeDeathsbyId, deleteDeaths, changeDeathsValidate} from '../../services/deaths';
import {fetchCategoryAges} from '../../services/categoryAges';
import {fetchCategoryLivestock} from '../../services/categoryLivestock';
import {fetchCategoryDeathsReason} from '../../services/categoryDeathsReason';

const DeathsGrid = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getContents = async () => {
      try {
        const response = await fetchDeaths({
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItems(response.data.size);
        setData(response.data.resultList);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [reset, stateFetchParams]);// eslint-disable-line

  const validationFindData = (dataTemp) => {
    let validated = true;
    dataTemp.forEach((row) => {
      validated = validated && row.validated;
    });

    return dataTemp.length ? validated : false;
  };

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    /*dataGridRows.forEach((row) => {
      if (row.notCreated) {
        return;
      }
      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, registerDeaths, changeDeathsbyId));
    });*/

    setIsLoading(true);
    const list = dataGridRows.map(item => item.id);
    const res = await changeDeathsValidate(checked, list);
    const response = await Promise.all(promises);
    setReset(!reset);
    setIsLoading(false);
    return response;
  };
  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      textAlign: 'Center',
      width: '200px',
      editType: 'datepicker',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => {
        // eslint-disable-next-line eqeqeq
        return value === 'true' || value;
      },
      popoverMapper: (line) => {
        return `${line.period}`;
      },
      mapper: (line, editMode) => {
        const [date] = line.date.split(' ');
        const [day, month, year] = date.split('/');

        if (!editMode) {
          // eslint-disable-next-line eqeqeq
          if (line.period === true) {
            return moment(new Date(year, month - 1, day))
              .locale('pt-br')
              .format('MM/YYYY');
          }

          return moment(new Date(year, month - 1, day))
            .locale('pt-br')
            .format('DD/MM/YYYY');
        }

        return moment(new Date(year, month - 1, day));
      },
      returnFormat: (value) => {
        if (moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00') === 'Invalid date') {
          return value;
        }
        return moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
      },
    },
    {
      key: 'amount',
      field: 'amount',
      name: 'Quantidade',
      width: '200px',
      textAlign: 'Center',
      required: true,
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'category_age',
      valueKey: 'category_age.title',
      field: 'category_age',
      name: 'Idade',
      textAlign: 'Center',
      width: '200px',
      required: true,
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryAges();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
      validations: {
        customer: Yup.string().required('Campo obrigatório'),
      },
    },
    {
      key: 'category_livestock',
      valueKey: 'category_livestock.title',
      field: 'category_livestock',
      name: 'Categoria',
      textAlign: 'Center',
      width: '300px',
      dependencies: ['category_age'],
      editType: 'dropdownedit',
      groupBy: (option) => {
        return option.category_age;
      },
      // eslint-disable-next-line no-shadow
      optionsFetch: async (state, dataGridState) => {
        let id = null;

        if (dataGridState.data.category_age) {
          id = dataGridState.data.category_age.id || dataGridState.data.category_age.value;
        }

        if (!id) {
          return [];
        }

        try {
          const response = await fetchCategoryLivestock({
            'wherein[categoryAge.id]': id,
            'match[show]': 'Mortes',
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
              category_age: item.category_age.stage,
            }),
          );
          objectTemp.sort((a, b) => -b.category_age.localeCompare(a.category_age));
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'category_death_reason',
      valueKey: 'category_death_reason.title',
      field: 'category_death_reason',
      name: 'Motivo',
      textAlign: 'Center',
      width: '320px',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryDeathsReason();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      textAlign: 'Center',
      width: '100px',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteDeaths(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  return (
    <>
      {stateMessage}
      {data && (
        <div className="salesGrid">
          <DataGrid
            onChange={async (dataGridInputs) => {
              if (!dataGridInputs.date) {
                const date = moment(new Date()).format('DD/MM/YYYY 00:00:00');
                Object.assign(dataGridInputs, {date});
              }
              Object.assign(dataGridInputs, {
                status: false,
              });
              const response = await createOrUpdateDataFull(dataGridInputs, state, registerDeaths, changeDeathsbyId);
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            validateCallback={validateHeaderCallback}
            editable=""
            width="max-content"
            actions={actions}
            totalItems={totalItems}
            accumulators={{
              'Total geral': {
                columns: ['amount'],
                itemOperation: (acc, line, key) => (acc += line[key]),
                resultOperation: (acc) => {
                  return maskInput({value: acc, type: MASKS.DOUBLE});
                },
              },
            }}
          />
        </div>
      )},
      {isLoading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}
    </>
  );
};

export default DeathsGrid;
