import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import moment from 'moment';
import DataGrid from '../../components/DataGrid';
import {maskInput, createOrUpdateDataFull} from '../../utils/utils';
import {MASKS, ROLES} from '../../utils/constants';
import {DataContext} from '../../utils/DataProvider';
import {fetchWeaning, registerWeaning, changeWeaningbyId, deleteWeaning, changeWeaningValidate} from '../../services/weaning';
import {fetchCategoryLivestock} from '../../services/categoryLivestock';

const WeaningGrid = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getContents = async () => {
      try {
        const response = await fetchWeaning({
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItems(response.data.size);
        setData(response.data.resultList);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [reset, stateFetchParams]);// eslint-disable-line

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    /*dataGridRows.forEach((row) => {
      if (row.notCreated) {
        return;
      }
      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, registerWeaning, changeWeaningbyId));
    });*/
    setIsLoading(true);

    const list = dataGridRows.map(item => item.id);

    await changeWeaningValidate(checked, list);

    const response = await Promise.all(promises);
    setReset(!reset);
    setIsLoading(false);
    return response;
  };

  const validationFindData = (dataTemp) => {
    let validated = true;
    dataTemp.forEach((row) => {
      validated = validated && row.validated;
    });

    return dataTemp.length ? validated : false;
  };

  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '15%',
      editType: 'datepicker',
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => {
        // eslint-disable-next-line eqeqeq
        return value === 'true' || value;
      },
      popoverMapper: (line) => {
        return `${line.period}`;
      },
      mapper: (line, editMode) => {
        const [date] = line.date.split(' ');
        const [day, month, year] = date.split('/');

        if (!editMode) {
          // eslint-disable-next-line eqeqeq
          if (line.period === true) {
            return moment(new Date(year, month - 1, day))
              .locale('pt-br')
              .format('MM/YYYY');
          }

          return moment(new Date(year, month - 1, day))
            .locale('pt-br')
            .format('DD/MM/YYYY');
        }

        return moment(new Date(year, month - 1, day));
      },
      returnFormat: (value) => {
        if (moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00') === 'Invalid date') {
          return value;
        }
        return moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
      },
    },
    {
      key: 'category_livestock',
      valueKey: 'category_livestock.title',
      field: 'category_livestock',
      required: true,
      name: 'Categoria de entrada',
      textAlign: 'Center',
      width: '25%',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryLivestock({
            'match[show]': 'Desmame',
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'birth_stamp',
      field: 'birth_stamp',
      name: 'Carimbo nascimento (mensal)',
      textAlign: 'Center',
      required: true,
      width: '20%',
      editType: 'datepicker',
      dateFormat: 'MM/yyyy',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      dateView: ['month', 'year'],
      returnFormat: (value) => {
        const dateFomarted = moment(value).locale('pt-br').format('DD/MM/YYYY 00:00:00');
        if (dateFomarted !== 'Invalid date') {
          return dateFomarted;
        }
        return value;
      },
    },
    {
      key: 'amount',
      field: 'amount',
      required: true,
      name: 'Quantidade',
      textAlign: 'Center',
      width: '15%  ',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'weight',
      field: 'weight',
      required: true,
      name: 'Peso',
      textAlign: 'Center',
      width: state.user.user_type !== ROLES.CUSTOMER ? '15%' : '25%',
      editType: 'weightedit',
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      textAlign: 'Center',
      width: '10%',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteWeaning(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  return (
    <>
      {stateMessage}
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (!dataGridInputs.date) {
              const date = moment(new Date()).format('DD/MM/YYYY 00:00:00');
              Object.assign(dataGridInputs, {date});
            }
            if (!dataGridInputs.birth_stamp) {
              const temp = moment(new Date()).format('DD/MM/YYYY 00:00:00');
              Object.assign(dataGridInputs, {birth_stamp: temp});
            }
            const response = await createOrUpdateDataFull(dataGridInputs, state, registerWeaning, changeWeaningbyId);
            setReset(!reset);
            return response;
          }}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          width="100%"
          maxHeight="100%"
          validateCallback={validateHeaderCallback}
          actions={actions}
          totalItems={totalItems}
          accumulators={{
            'Total geral': {
              columns: ['amount', 'weight'],
              itemOperation: (acc, line, key) => {
                if (key === 'weight') {
                  return (acc += line.weight * line.amount);
                }

                return (acc += line[key]);
              },
              resultOperation: (acc) => {
                return maskInput({
                  value: acc,
                  type: MASKS.DOUBLE,
                });
              },
            },
            Ponderado: {
              columns: ['weight'],
              itemOperation: (acc, line, key) => {
                if (acc) {
                  if (key === 'weight') {
                    return {
                      sum: (acc.sum += line[key] * line.amount),
                      quantity: (acc.quantity += line.amount),
                    };
                  }
                }
                return {
                  sum: acc ? (acc.sum += line[key] * line.amount) : line[key] * line.amount,
                  quantity: acc ? (acc.quantity += line.amount) : line.amount,
                };
              },
              resultOperation: (acc) => {
                return maskInput({
                  value: acc.sum / acc.quantity,
                  type: MASKS.DOUBLE,
                });
              },
            },
          }}
        />
      )},
      {isLoading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}
    </>
  );
};

export default WeaningGrid;
