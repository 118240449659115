import apiNew from '../apiNew';

const fetchHerdDeparture = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/herd_departure`, {
    params: {environment, ...params},
  });
};
const fetchHerdDepartureKPI = (params = {}) => {
  return apiNew.get(`/herd_departure/graphic_kpi`, {
    params: {
      ...params,
    },
  });
};

const fetchHerdDeparturebyId = (id, params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/herd_departure/${id}`, {
    params: {environment, ...params},
  });
};
const fetchHerdDepartureLine = (params = {}) => {
  return apiNew.get(`/herd_departure/graphic_line`, {
    params: {
      ...params,
    },
  });
};
const fetchHerdDepartureValidated = (type, params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/${type}/isValidated`, {
    params: {environment, ...params},
  });
};

const registerHerdDeparture = (body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.post(`/herd_departure`, {environment, ...body});
};

const changeHerdDeparturebyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.put(`/herd_departure/${id}`, {environment, ...body});
};

const changeHerdDepartureValidate = (type, body = {}) => {
  return apiNew.put(`/herd_departure/validate?type=${type}`, body);
};

const deleteHerdDeparture = (id) => {
  return apiNew.delete(`/herd_departure/${id}`);
};

export {
  fetchHerdDeparture,
  fetchHerdDeparturebyId,
  registerHerdDeparture,
  changeHerdDeparturebyId,
  deleteHerdDeparture,
  fetchHerdDepartureKPI,
  fetchHerdDepartureLine,
  fetchHerdDepartureValidated,
  changeHerdDepartureValidate,
};
