/* eslint-disable camelcase */
import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import moment from 'moment';
import DataGrid from '../../components/DataGrid';
import {maskInput, createOrUpdateDataFull, isObject} from '../../utils/utils';
import {MASKS} from '../../utils/constants';
import {DataContext} from '../../utils/DataProvider';
import {fetchConfinements, registerConfinements, changeConfinementsbyId, deleteConfinements, changeConfinementsValidate} from '../../services/confinements';
import {fetchCategoryLivestock} from '../../services/categoryLivestock';
import {fetchCategorySupplyType} from '../../services/categorySupplyType';
import {fetchCategoryAnimalType} from '../../services/categoryAnimalType';
import {fetchCategoryWagonType} from '../../services/categoryWagonType';
import {fetchCategoryRacialGroup} from '../../services/categoryRacialGroup';
import GenerateForm from '../../components/GenerateForm';
import {fetchConfinementDetails, registerConfinementDetails} from '../../services/ConfinementDetails';
import {fetchCategoryDsmTechnology} from '../../services/categoryDsmTechnology';

let flag1 = false;
let flag2 = false;

const ConfinementGrid = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [reset, setReset] = useState(false);
  const [dataConfinementDetails, setDataConfinementDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const confinamentEnums = [
    {
      value: 'CONFINAMENTO',
      label: 'Confinamento',
      // category_age: item.category_age.stage,
    },
    {
      value: 'SEMICONFINAMENTO_1',
      label: 'Semiconfinamento 1% do PV',
      // category_age: item.category_age.stage,
    },
    {
      value: 'SEMICONFINAMENTO_15',
      label: 'Semiconfinamento 1,5% do PV',
      // category_age: item.category_age.stage,
    },
    {
      value: 'SEMICONFINAMENTO_2',
      label: 'Semiconfinamento 2% do PV',
      // category_age: item.category_age.stage,
    },
  ];
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const response = await fetchConfinements({
          ...stateFetchParams,
          ...filterParams,
        });
        const dataFetchConfinementeDetails = await fetchConfinementDetails(stateFetchParams);
        setDataConfinementDetails(dataFetchConfinementeDetails?.data?.size ? dataFetchConfinementeDetails?.data?.resultList[0] : {});
        setTotalItems(response.data.size);
        setData(response.data.resultList);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    })();
  }, [reset, stateFetchParams]);// eslint-disable-line

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    /*dataGridRows.forEach((row) => {
      if (row.notCreated) {
        return;
      }
      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, registerConfinements, changeConfinementsbyId));
    });*/
    
    setIsLoading(true);
    const list = dataGridRows.map(item => item.id);
    await changeConfinementsValidate(checked, list);
    const response = await Promise.all(promises);
    setReset(!reset);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    return response;
  };

  const validationFindData = (dataTemp) => {
    let validated = true;
    dataTemp.forEach((row) => {
      validated = validated && row.validated;
    });

    return dataTemp.length ? validated : false;
  };

  const columns = [
    {
      key: 'date_entry',
      field: 'date_entry',
      required: true,
      name: 'Data entrada',
      textAlign: 'Center',
      width: '160px',
      editType: 'datepicker',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      // returnFormat: (value) => {
      //   return moment(value).format('DD/MM/YYYY 00:00:00');
      // },
    },
    {
      key: 'date_departure',
      field: 'date_departure',
      name: 'Data saída',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'datepicker',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      // returnFormat: (value) => {
      //   return moment(value).format('DD/MM/YYYY 00:00:00');
      // },
    },
    {
      key: 'amount',
      field: 'amount',
      name: 'Quantidade',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'category_livestock',
      valueKey: 'category_livestock.title',
      field: 'category_livestock',
      name: 'Categoria',
      required: true,
      textAlign: 'Center',
      groupBy: (option) => {
        return option.category_age;
      },
      width: '240px',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        setIsLoading(true);
        try {
          const response = await fetchCategoryLivestock({
            'match[show]': 'Confinamento',
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
              category_age: item.category_age.stage,
            }),
          );
          objectTemp.sort((a, b) => b.category_age.localeCompare(a.category_age));
          setIsLoading(false);
          return objectTemp;
        } catch (e) {
          setIsLoading(false);
          return [];
        }
      },
    },
    {
      key: 'category_racial_group',
      valueKey: 'category_racial_group.title',
      field: 'category_racial_group',
      name: 'Grupo racial',
      required: true,
      textAlign: 'Center',
      // groupBy: (option) => {
      //   return option.category_age;
      // },
      width: '240px',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryRacialGroup();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
              // category_age: item.category_age.stage,
            }),
          );

          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'category_animal_type',
      valueKey: 'category_animal_type.title',
      field: 'category_animal_type',
      name: 'Tipo Animal',
      required: true,
      textAlign: 'Center',
      // groupBy: (option) => {
      //   return option.category_age;
      // },
      width: '240px',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryAnimalType();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
              // category_age: item.category_age.stage,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'type',
      valueKey: 'type',
      field: 'type',
      name: 'Tipo de sistema de terminação',
      required: true,
      textAlign: 'Center',
      // groupBy: (option) => {
      //   return option.category_age;
      // },
      width: '270px',
      editType: 'dropdownedit',
      mapper: (line, editMode) => {
        const option = confinamentEnums.find((responseValue) => responseValue.value === line.type);
        if (!editMode) {
          return option.label;
        }
        return {value: option.value, label: option.label};
      },
      returnFormat: (value) => {
        if (isObject(value)) {
          return value.value;
        }
        return value;
      },
      optionsFetch: () => {
        try {
          return confinamentEnums;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'weight_entry',
      field: 'weight_entry',
      required: true,
      name: 'Peso entrada (kg)',
      textAlign: 'Center',
      width: '160px',
      editType: 'weightedit',
    },
    {
      key: 'entry_price',
      field: 'entry_price',
      required: true,
      name: 'Preço de entrada(R$/cabeça)',
      textAlign: 'Center',
      width: '160px',
      editType: 'moneyedit',
    },
    {
      key: 'weight_departure',
      field: 'weight_departure',
      name: 'Peso saída (kg)',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'weightedit',
    },
    {
      key: 'weight_death',
      field: 'weight_death',
      name: 'Peso morto (kg)',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'weightedit',
    },
    {
      key: 'arroba_sale_price',
      field: 'arroba_sale_price',
      name: 'Preço de venda/@',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'moneyedit',
    },
    {
      key: 'daily_cost',
      field: 'daily_cost',
      required: true,
      name: 'Custo diário Alimentar (R$/dia)',
      textAlign: 'Center',
      width: '160px',
      editType: 'moneyedit',
    },
    {
      key: 'daily_cost_operational',
      field: 'daily_cost_operational',
      required: true,
      name: 'Custo diário operacional (R$/dia)',
      textAlign: 'Center',
      width: '160px',
      editType: 'moneyedit',
    },
    {
      tooltip: `Custo do protocolo sanitário em todo o período de confinamento.`,
      tooltipPosition: 'right',
      key: 'daily_cost_health',
      field: 'daily_cost_health',
      required: true,
      name: 'Custo sanidade (R$/cab. no período)',
      textAlign: 'Center',
      width: '270px',
      editType: 'moneyedit',
    },
    {
      key: 'trough_refuse',
      field: 'trough_refuse',
      name: 'Refugo de Cocho',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'numericedit',
      extraParams: {
        minValue: 0,
      },
      tooltip: `Inserir o número de animais no refugo de cocho`,
      tooltipPosition: 'right',
    },

    {
      key: 'concentrated_consumption',
      field: 'concentrated_consumption',
      // required: true,
      name: 'Consumo de concentrado MN (kg/cab/dia)',
      textAlign: 'Center',
      width: '200px',
      editType: 'weightedit',
      extraParams: {
        formatedValue: async (col) => {
          const type = col?.data?.type?.value || col?.data?.type || null;
          if (type && type === 'CONFINAMENTO') {
            return '0';
          }
          return null;
        },
      },
      required: (stateContext) => {
        const type = stateContext?.data?.type?.value || stateContext?.data?.type || null;
        if (type && type !== 'CONFINAMENTO') {
          return true;
        }
        return false;
      },
      disabledEdit: (dataLine) => {
        const type = dataLine?.data?.type?.value || dataLine?.data?.type || null;
        if (type && type === 'CONFINAMENTO') {
          return true;
        }
        return false;
      },
    },

    {
      key: 'dry_matter_consumption',
      field: 'dry_matter_consumption',
      // required: true,
      name: 'Consumo de matéria seca (kg/cab/dia)',
      width: '150px',
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        formatedValue: async (col) => {
          const type = col?.data?.type?.value || col?.data?.type || null;
          if (type && type !== 'CONFINAMENTO') {
            return '0';
          }
          return null;
        },
      },
      required: (stateContext) => {
        const type = stateContext?.data?.type?.value || stateContext?.data?.type || null;
        if (type && type === 'CONFINAMENTO') {
          return true;
        }
        return false;
      },
      disabledEdit: (dataLine) => {
        const type = dataLine?.data?.type?.value || dataLine?.data?.type || null;
        if (type && type !== 'CONFINAMENTO') {
          return true;
        }
        return false;
      },
    },
    {
      key: 'mortality',
      field: 'mortality',
      required: false,
      name: 'Mortes',
      textAlign: 'Center',
      width: '160px',
      editType: 'numericedit',
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      width: '140px',
      textAlign: 'Center',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteConfinements(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  const roughage_main_sourceList = [
    {value: 'SILAGEM_MILHO', label: 'Silagem de Milho'},
    {value: 'BAGACO_CANA', label: 'Bagaço de cana'},
    {value: 'SILAGEM_SORGO', label: 'Silagem de sorgo'},
    {value: 'SILAGEM_CAPIM', label: 'Silagem de capim'},
    {value: 'OUTROS', label: 'Outros'},
  ];

  const energy_main_sourceList = [
    {value: 'MILHO', label: 'Milho'},
    {value: 'SORGO', label: 'Sorgo'},
    {value: 'POLPA_CITRICA', label: 'Polpa Cítrica'},
    {value: 'SGUM', label: 'SGUM / reidratado'},
    {value: 'OUTROS', label: 'Outros'},
  ];

  const [dropdownArrow, setDropdownArrow] = useState(true);

  useEffect(() => {
    if (flag1 && !flag2) {
      setDropdownArrow(!Object.keys(dataConfinementDetails).length);
      flag2 = true;
    }

    if (!flag1 && !flag2) {
      flag1 = true;
    }
  }, [dataConfinementDetails]);

  function handleDropdown() {
    setDropdownArrow((prev) => !prev);
  }

  const DropDown = ({children}) => {
    return (
      <div className="custom-dropdown-header" onClick={handleDropdown}>
        {children}
        <i className={`fas fa-chevron-${dropdownArrow ? 'down' : 'right'}`} />
      </div>
    );
  };

  const fieldForm = [
    {
      name: 'Caracteriza confinamento',
      component: DropDown,
      dropdownState: dropdownArrow,
      fields: [
        {
          label: 'Principal fonte de volumoso',
          name: 'roughage_main_source',
          type: 'autocomplete',
          hasPlaceholder: true,
          currentValue: dataConfinementDetails?.roughage_main_source
            ? {
                value: dataConfinementDetails?.roughage_main_source,
                label: roughage_main_sourceList.find((item) => item.value === dataConfinementDetails?.roughage_main_source).label,
              }
            : null,
          // value: dataConfinementDetails?.roughage_main_source,
          placeholder: ' ',
          required: true,
          disabled: false,
          onChange: async (e) => {
            let body = {
              farm: state.farm,
              harvest: state.harvest,
              customer: state.customer,
              ...dataConfinementDetails,
              roughage_main_source: e?.value || null,
            };
            if (!body?.created_by) {
              body = {...body, ...{created_by: state.user}};
            }
            if (body?.id) {
              body = {
                ...body,
                updated_by: state.user,
                updated_at: moment().locale('pt-br').format('DD/MM/YYYY 00:00:00'),
              };
            }
            delete body.farm.diagnoses;
            const result = await registerConfinementDetails(body);
            setDataConfinementDetails(result ? {...body, id: result.id, title: result.title} : body);
          },
          // optionsFetch: async () => {},
          options: roughage_main_sourceList,
          // validations: {
          //   category_confinement: Yup.string().required('Campo obrigatório'),
          // },
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Principal fonte energética',
          name: 'energy_main_source',
          type: 'autocomplete',
          hasPlaceholder: true,
          currentValue: dataConfinementDetails?.energy_main_source
            ? {
                value: dataConfinementDetails?.energy_main_source,
                label: energy_main_sourceList.find((item) => item.value === dataConfinementDetails?.energy_main_source).label,
              }
            : null,
          placeholder: ' ',
          required: true,
          disabled: false,
          onChange: async (e) => {
            let body = {
              farm: state.farm,
              harvest: state.harvest,
              customer: state.customer,
              ...dataConfinementDetails,
              energy_main_source: e?.value || null,
            };
            if (!body?.created_by) {
              body = {...body, ...{created_by: state.user}};
            }
            if (body?.id) {
              body = {
                ...body,
                updated_by: state.user,
                updated_at: moment().locale('pt-br').format('DD/MM/YYYY 00:00:00'),
              };
            }
            delete body.farm.diagnoses;
            const result = await registerConfinementDetails(body);
            setDataConfinementDetails(result ? {...body, id: result.id, title: result.title} : body);
          },
          // optionsFetch: async () => {},
          options: energy_main_sourceList,
          // validations: {
          //   category_confinement: Yup.string().required('Campo obrigatório'),
          // },
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Tipo de Vagão',
          name: 'category_wagon_type',
          type: 'autocomplete',
          hasPlaceholder: true,
          currentValue: dataConfinementDetails?.category_wagon_type
            ? {
                value: dataConfinementDetails?.category_wagon_type.id,
                label: dataConfinementDetails?.category_wagon_type.title,
              }
            : null,
          placeholder: ' ',
          required: true,
          disabled: false,
          onChange: async (e) => {
            let body = {
              farm: state.farm,
              harvest: state.harvest,
              customer: state.customer,
              ...dataConfinementDetails,
              category_wagon_type: {id: e?.value, title: e?.label} || null,
            };
            if (!body?.created_by) {
              body = {...body, ...{created_by: state.user}};
            }
            if (body?.id) {
              body = {
                ...body,
                updated_by: state.user,
                updated_at: moment().locale('pt-br').format('DD/MM/YYYY 00:00:00'),
              };
            }
            delete body.farm.diagnoses;
            const result = await registerConfinementDetails(body);
            setDataConfinementDetails(result ? {...body, id: result.id, title: result.title} : body);
          },
          optionsFetch: async () => {
            try {
              const response = await fetchCategoryWagonType();
              const objectTemp = [];
              response.data.resultList.map((item) =>
                objectTemp.push({
                  value: item.id,
                  label: item.title,
                }),
              );
              return objectTemp;
            } catch (e) {
              return [];
            }
          },
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Tipo de fornecimento',
          name: 'category_supply_type',
          type: 'autocomplete',
          hasPlaceholder: true,
          currentValue: dataConfinementDetails?.category_supply_type
            ? {
                value: dataConfinementDetails?.category_supply_type.id,
                label: dataConfinementDetails?.category_supply_type.title,
              }
            : null,
          placeholder: ' ',
          required: true,
          disabled: false,
          onChange: async (e) => {
            let body = {
              farm: state.farm,
              harvest: state.harvest,
              customer: state.customer,
              ...dataConfinementDetails,
              category_supply_type: {id: e?.value, title: e?.label} || null,
            };
            if (!body?.created_by) {
              body = {...body, ...{created_by: state.user}};
            }
            if (body?.id) {
              body = {
                ...body,
                updated_by: state.user,
                updated_at: moment().locale('pt-br').format('DD/MM/YYYY 00:00:00'),
              };
            }
            delete body.farm.diagnoses;
            const result = await registerConfinementDetails(body);
            setDataConfinementDetails(result ? {...body, id: result.id, title: result.title} : body);
          },
          optionsFetch: async () => {
            try {
              const response = await fetchCategorySupplyType();
              const objectTemp = [];
              response.data.resultList.map((item) =>
                objectTemp.push({
                  value: item.id,
                  label: item.title,
                }),
              );
              return objectTemp;
            } catch (e) {
              return [];
            }
          },
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Tecnologia DSM',
          name: 'list_category_dsm_technology',
          type: 'autocompleteMultiple',
          hasPlaceholder: true,
          currentValue: dataConfinementDetails?.list_category_dsm_technology?.map((item) => {
            return {
              value: item.id,
              label: item.title,
            };
          }),
          placeholder: ' ',
          required: true,
          disabled: false,
          onChange: (e) => {
            let body = {
              farm: state.farm,
              harvest: state.harvest,
              customer: state.customer,
              ...dataConfinementDetails,
              list_category_dsm_technology: e.map((item) => {
                return {id: item.value, title: item.label};
              }),
            };
            if (!body?.created_by) {
              body = {...body, ...{created_by: state.user}};
            }
            if (body?.id) {
              body = {
                ...body,
                updated_by: state.user,
                updated_at: moment().locale('pt-br').format('DD/MM/YYYY 00:00:00'),
              };
            }
            setDataConfinementDetails(body);
            delete body.farm.diagnoses;
            registerConfinementDetails(body);
          },

          optionsFetch: async () => {
            try {
              const response = await fetchCategoryDsmTechnology();
              const objectTemp = [];
              response.data.resultList.map((item) =>
                objectTemp.push({
                  value: item.id,
                  label: item.title,
                }),
              );
              return objectTemp;
            } catch (e) {
              return [];
            }
          },
          // options: [
          //   {value: true, label: 'CRINA'},
          //   {value: false, label: 'RUMI'},
          //   {value: '1', label: 'HY-D'},
          // ],
          // validations: {
          //   category_confinement: Yup.string().required('Campo obrigatório'),
          // },
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  return (
    <>
      {stateMessage}
      {data && (
        <div className="confinement">
          <div className="p-4  confinementDetail">
            <GenerateForm
              onSubmit={(e) => e.preventDefault()}
              groupFields={fieldForm}
              settings={{
                button: {text: 'Avançar'},
                back_button: {text: 'Voltar'},
                hide: true,
              }}
              // showBackButton
              // onBack={() => setPage(page > 0 ? page - 1 : page)}
            />
          </div>
          <DataGrid
            onChange={async (dataGridInputs) => {
              Object.assign(dataGridInputs, {
                status: false,
              });
              if (dataGridInputs?.type?.id) {
                dataGridInputs = {
                  ...dataGridInputs,
                  type: dataGridInputs.type.id,
                };
              }
              if (dataGridInputs?.amount) {
                dataGridInputs = {
                  ...dataGridInputs,
                  amount: parseFloat(dataGridInputs.amount),
                };
              }
              if (dataGridInputs?.concentrated_consumption) {
                dataGridInputs = {
                  ...dataGridInputs,
                  concentrated_consumption: parseFloat(dataGridInputs.concentrated_consumption),
                };
              }
              if (dataGridInputs?.daily_cost) {
                dataGridInputs = {
                  ...dataGridInputs,
                  daily_cost: parseFloat(dataGridInputs.daily_cost),
                };
              }
              if (dataGridInputs?.entry_price) {
                dataGridInputs = {
                  ...dataGridInputs,
                  entry_price: parseFloat(dataGridInputs.entry_price),
                };
              }
              if (dataGridInputs?.daily_cost_operational) {
                dataGridInputs = {
                  ...dataGridInputs,
                  daily_cost_operational: parseFloat(dataGridInputs.daily_cost_operational),
                };
              }
              if (dataGridInputs?.dry_matter_consumption) {
                dataGridInputs = {
                  ...dataGridInputs,
                  dry_matter_consumption: parseFloat(dataGridInputs.dry_matter_consumption),
                };
              }
              if (dataGridInputs?.daily_cost_health) {
                dataGridInputs = {
                  ...dataGridInputs,
                  daily_cost_health: parseFloat(dataGridInputs.daily_cost_health),
                };
              }
              if (dataGridInputs?.trough_refuse) {
                dataGridInputs = {
                  ...dataGridInputs,
                  trough_refuse: parseFloat(dataGridInputs.trough_refuse),
                };
              }
              if (dataGridInputs?.weight_death) {
                dataGridInputs = {
                  ...dataGridInputs,
                  weight_death: parseFloat(dataGridInputs.weight_death),
                };
              }
              if (dataGridInputs?.weight_departure) {
                dataGridInputs = {
                  ...dataGridInputs,
                  weight_departure: parseFloat(dataGridInputs.weight_departure),
                };
              }
              if (dataGridInputs?.weight_entry) {
                dataGridInputs = {
                  ...dataGridInputs,
                  weight_entry: parseFloat(dataGridInputs.weight_entry),
                };
              }
              if (dataGridInputs?.mortality) {
                dataGridInputs = {
                  ...dataGridInputs,
                  mortality: parseInt(dataGridInputs.mortality, 10),
                };
              }
              if (dataGridInputs?.date_departure === 'Invalid date') {
                dataGridInputs = {
                  ...dataGridInputs,
                  date_departure: data.find((item) => item.id === dataGridInputs.id).date_departure,
                };
              }
              if (dataGridInputs?.date_entry === 'Invalid date') {
                dataGridInputs = {
                  ...dataGridInputs,
                  date_entry: data.find((item) => item.id === dataGridInputs.id).date_entry,
                };
              }
              if (typeof dataGridInputs.date_entry === 'object') {
                dataGridInputs.date_entry = moment(dataGridInputs.date_entry).format('DD/MM/YYYY 00:00:00');
              }
              if (typeof dataGridInputs.date_departure === 'object') {
                dataGridInputs.date_departure = moment(dataGridInputs.date_departure).format('DD/MM/YYYY 00:00:00');
              }

              const response = await createOrUpdateDataFull(dataGridInputs, state, registerConfinements, changeConfinementsbyId);
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            width="max-content"
            maxHeight="540px"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            validateCallback={validateHeaderCallback}
            actions={actions}
            totalItems={totalItems}
            accumulators={{
              'Total geral': {
                columns: ['amount', 'weight_entry', 'weight_departure', 'weight_death', 'daily_cost', 'dry_matter_consumption'],
                itemOperation: (acc, line, key) => {
                  if (key === 'weight_entry') {
                    return (acc += line[key] * line.amount);
                  }
                  if (key === 'weight_departure') {
                    return (acc += line[key] * line.amount);
                  }
                  if (key === 'weight_death') {
                    return (acc += line[key] * line.amount);
                  }
                  if (key === 'daily_cost') {
                    return (acc += line[key] * line.amount);
                  }
                  if (key === 'dry_matter_consumption') {
                    return (acc += line[key] * line.amount);
                  }
                  return (acc += line[key]);
                },
                resultOperation: (acc, key) => {
                  if (key === 'daily_cost') {
                    return maskInput({value: acc, type: MASKS.MONEY});
                  }

                  return maskInput({
                    value: acc,
                    type: MASKS.DOUBLE,
                  });
                },
              },

              'Ponderado geral': {
                columns: ['weight_entry', 'weight_departure', 'weight_death', 'daily_cost', 'dry_matter_consumption'],
                itemOperation: (acc, line, key) => {
                  if (acc) {
                    if (key === 'weight_entry') {
                      return {
                        sum: (acc.sum += line[key] * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                    if (key === 'weight_departure') {
                      return {
                        sum: (acc.sum += line[key] * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                    if (key === 'weight_death') {
                      return {
                        sum: (acc.sum += line[key] * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                    if (key === 'daily_cost') {
                      return {
                        sum: (acc.sum += line[key] * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                    if (key === 'dry_matter_consumption') {
                      return {
                        sum: (acc.sum += line[key] * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                  }
                  return {
                    sum: acc ? (acc.sum += line[key] * line.amount) : line[key] * line.amount,
                    quantity: acc ? (acc.quantity += line.amount) : line.amount,
                  };
                },
                resultOperation: (acc, key) => {
                  if (key === 'daily_cost') {
                    return maskInput({
                      value: acc.sum / acc.quantity,
                      type: MASKS.MONEY,
                    });
                  }
                  return maskInput({
                    value: acc.sum / acc.quantity,
                    type: MASKS.DOUBLE,
                  });
                },
              },
            }}
          />
        </div>
      )},
      {isLoading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}
    </>
  );
};

export default ConfinementGrid;
