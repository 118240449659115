import React, {useEffect, useContext, useState} from 'react';
import './style.scss';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {fetchTeams, changeTeambyId, registerTeam, changeTeamValidate} from '../../services/teams';
import {MASKS} from '../../utils/constants';
import {
  maskInput,
  createOrUpdateData,
  createOrUpdateDataFull,
} from '../../utils/utils';
import generalColumns from './GeneralDatagridModel';
import {fetchCategoryFunctions} from '../../services/categoryFunctions';

const General = () => {
  const {state, stateFetchParams, stateMessage} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const loadTeams = async () => {
      const response = await fetchTeams(stateFetchParams);
      const categoryFunctions = await fetchCategoryFunctions();
      const normalizedData = categoryFunctions.data.resultList.map(
        (categoryFunction) => {
          const teamOfThisFunction = response.data.resultList.find(
            (team) => team.category_function.id === categoryFunction.id,
          );

          if (!teamOfThisFunction) {
            categoryFunction.category_function = {
              id: categoryFunction.id,
              title: categoryFunction.title,
            };

            categoryFunction.notCreated = true;
            delete categoryFunction.title;
            generalColumns.forEach((column) => {
              if (!categoryFunction[column.field] && column.editType) {
                categoryFunction[column.field] = null;
              }
            });
            categoryFunction.average_salary = null;
            categoryFunction.average_salary_benefit = null;
          } else {
            return teamOfThisFunction;
          }

          return categoryFunction;
        },
      );

      setData(normalizedData);
      setTotalItems(response.data.size);
      setIsLoading(false);
    };

    loadTeams();
  }, [reset, stateFetchParams]);

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      hide: false,
      icon: 'fas fa-trash-alt',
      onClick: async (dataLine, line, cel) => {
        try {
          Object.assign(dataLine, {[cel]: null});
          if (dataLine.notCreated) {
            delete dataLine.id;
          }
          delete dataLine.notCreated;

          const resp = await createOrUpdateDataFull(
            dataLine,
            state,
            registerTeam,
            changeTeambyId,
          );
          // const response = await deleteTeam(value.id);
          // setReset(!reset);
          return resp;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
   /* const promises = [];
    dataGridRows.forEach((row) => {
      if (row.notCreated) {
        return; 
      }
      promises.push(
        createOrUpdateData(
          {id: row.id, validated: checked},
          state,
          registerTeam,
          changeTeambyId,
        ),
      );
    });*/

    setIsLoading(true);

    const list = dataGridRows.map(item => item.id);

    await changeTeamValidate(checked, list); 

    const promises = [];
    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };
  return (
    <>
      {stateMessage}
      {data && (
        <div className="teamGrid">
          <DataGrid
            onChange={async (dataGridInputs) => {
              if (dataGridInputs.notCreated) {
                delete dataGridInputs.id;
              }
              delete dataGridInputs.notCreated;

              const response = await createOrUpdateDataFull(
                dataGridInputs,
                state,
                registerTeam,
                changeTeambyId,
              );
              setReset(!reset);
              return response;
            }}
            maxHeight="100%"
            fixedAccumulator
            header={generalColumns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            disabledPaginate
            actions={actions}
            accumulators={{
              Total: {
                columns: ['amount', 'average_salary', 'average_salary_benefit'],
                itemOperation: (acc, line, key) => {
                  if (key !== 'amount') {
                    acc += line[key] * line.amount;
                  } else {
                    acc += line[key];
                  }
                  return acc;
                },
                resultOperation: (acc, key) => {
                  if (key !== 'amount') {
                    return maskInput({value: acc, type: MASKS.MONEY});
                  }

                  return acc;
                },
              },
              Ponderado: {
                columns: ['average_salary', 'average_salary_benefit'],
                itemOperation: (acc, line, key) => {
                  if (!acc) {
                    return {
                      sum: line[key] * line.amount,
                      quantity: line.amount,
                    };
                  }
                  return {
                    sum: acc.sum + line[key] * line.amount,
                    quantity: acc.quantity + line.amount,
                  };
                },
                resultOperation: (acc) => {
                  return maskInput({
                    value: acc.sum / acc.quantity,
                    type: MASKS.MONEY,
                  });
                },
              },
            }}
            totalItems={totalItems}
            groupBy="category_work_front"
            validateCallback={validateHeaderCallback}
          />
        </div>
      )},
      {isLoading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}
    </>
  );
};
export default General;
